import React from "react"
import mainImage from '../images/kafe.jpg';
import logo from '../images/logo.png';
import service1 from '../images/service1.jpg';
import service2 from '../images/service2.jpg';
import service3 from '../images/service3.jpg';
import service4 from '../images/service4.jpg';
import styled from 'styled-components';

import Layout from "../components/layout"
import SEO from "../components/seo"

const LogoTop = styled.img`
  width: 250px;
  padding: 30px;
`;

const MainImage = styled.div`
  background-image: url(${mainImage});
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  background-position: center center;
  height: 550px;
  
  @media only screen and (min-width: 769px) {
    height: 700px;
  }
`

const Company = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 70px 50px;
  
  @media only screen and (min-width: 769px) {
    flex-direction: row;
  }
`

const LogoCompany = styled.img`
  width: 100%;
  max-width: 600px;
  height: auto;
  padding: 30px;
`;

const CompanyDescription = styled.p`
  font-size: 16px;
  color: #124963;
`

const Projects = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  
  @media only screen and (min-width: 769px) {
    flex-direction: row;
    justify-content: space-between;
  }
`

const Project = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  
  ul {
    list-style:none;
        
    li {
      color: #124963;
      font-size: 18px;
      font-weight: 300;
      display: flex;
      align-items: center;
      
      &::before{
        content: "";
        display: inline-block;
        width: 7px;
        height: 7px;
        margin-right: 5px;
        background: #b6bb33;
      }
    }
  }
  
  @media only screen and (min-width: 769px) {
    width: 24%;
  }
`

const ProjectImage = styled.div`
  background-image: url(${({src})=>src});
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  background-position: center center;
  width: 100%;
  height: 400px;
  margin-bottom: 20px;
`

const IndexPage = () => (
  <Layout>
    <SEO title="Αρχική" />
    <MainImage>
      <LogoTop src={logo}/>
    </MainImage>
    <Company>
      <LogoCompany src={logo} alt="GreenBees logo"/>
      <CompanyDescription>
        Οι Green Bees Μηχανικοί είναι ένα καινοτόμο γραφείο αρχιτεκτονικού και αστικού σχεδιασμού.<br/>
        Οι σύγχρονες αρχές σχεδιασμού, οι ανάγκες του πελάτη, η λειτουργικότητα, και η δημιουργική ομάδα συνθέτουν ένα διαχρονικό αποτέλεσμα σε κάθε έργο.<br/>
        Στόχος του γραφείου είναι η δημιουργία χώρων με προτεραιότητα τον χρήστη και τις ανάγκες του.<br/>
        <br/>
        Το γραφείο δημιουργήθηκε το 2010 από την Ειρήνη Λούκου, αρχιτέκτονα μηχανικό, και η έδρα του γραφείου βρίσκεται στο Ηράκλειο Κρήτης.
        <br/>
        <br/>
        Ομάδα εργασίας<br/><br/>
        Ειρήνη Λούκου - Αρχιτέκτων Μηχανικός Ε.Μ.Π.<br/>
        Μαίρη Καμπανού - Αρχιτέκτων Μηχανικός Α.Π.Θ.<br/>
        Αγησίλαος Ζαχαριουδάκης - Πολιτικός Μηχανικός Π.Θ.<br/>
      </CompanyDescription>
    </Company>
    <Projects>
      <Project>
        <ProjectImage src={service1}></ProjectImage>
        <ul>
          <li>Αρχιτεκτονικές μελέτες</li>
          <li>Ανακαινίσεις χώρων</li>
          <li>Οικοδομικές άδειες</li>
        </ul>
      </Project>
      <Project>
        <ProjectImage src={service2}></ProjectImage>
        <ul>
          <li>Σχεδιασμός επαγγελματικών χώρων</li>
          <li>Άδειες λειτουργίας</li>
        </ul>
      </Project>
      <Project>
        <ProjectImage src={service3}></ProjectImage>
        <ul>
          <li>Κατασκευή</li>
          <li>Επίβλεψη</li>
        </ul>
      </Project>
      <Project>
        <ProjectImage src={service4}></ProjectImage>
        <ul>
          <li>Ενεργειακά Πιστοποιητικά (ΠΕΑ)</li>
          <li>Εξοικονομώ</li>
          <li>Αυθαίρετα</li>
        </ul>
      </Project>
    </Projects>
  </Layout>
)

export default IndexPage
